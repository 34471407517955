/** @jsx jsx */
import { jsx } from "theme-ui"
import BlogListItem from "./blog-list-item"

const Listing = ({ posts, className, showTags = true }) => (
  <section sx={{ mb: [3, 4, 5] }} className={className}>
    {posts.map(post => (
      <BlogListItem key={post.slug} post={post} showTags={showTags} />
    ))}
  </section>
)

export default Listing
