import { graphql } from "gatsby"
import HomepageComponent from "../components/homepage"

export default HomepageComponent

export const query = graphql`
  query($formatString: String!) {
    allPost(
      filter: { draft: { ne: true } }
      sort: { fields: date, order: DESC }
      limit: 3
    ) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        excerpt
        timeToRead
        description
        tags {
          name
          slug
        }
      }
    }
    avatar: file(relativePath: { eq: "photo.png" }) {
      childImageSharp {
        fixed(width: 224, height: 224, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
