/** @jsx jsx */
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Heading, jsx } from "theme-ui"
import useBlogConfig from "../hooks/use-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import Layout from "./layout"
import Listing from "./listing"
import Title from "./title"

const HomepageComponent = ({ posts, avatar }) => {
  const { basePath, blogPath } = useBlogConfig()

  return (
    <Layout>
      <section sx={{ mb: [3, 4, 5], textAlign: "center" }}>
        <Img fixed={avatar} sx={{ borderRadius: "full" }} />
        <Heading variant="styles.h1">Welcome to Curiousily!</Heading>

        <p sx={{ fontSize: 2, ml: [2, 3, 5], mr: [2, 3, 5] }}>
          Hey, I am Venelin. It's my pleasure to accompany you on your adventure
          throughout the amazing world of Artificial Intelligence! Let's do
          this!
        </p>
      </section>
      <Title text="Latest Posts">
        <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>
          Read all posts
        </Link>
      </Title>
      <Listing posts={posts} showTags={false} />
    </Layout>
  )
}

const Homepage = ({ data }) => {
  const { allPost, avatar } = data

  return (
    <HomepageComponent
      posts={allPost.nodes}
      avatar={avatar.childImageSharp.fixed}
    />
  )
}

export default Homepage
